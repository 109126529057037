// RightNavbar.js
import React from "react";
import '../../../assets/css/user/rightNavbar.css';

const RightNavbar = () => {
    return (
        <div className="cat-container">
        <img src="/images/pusheen-cat.gif" alt="Moving cat" className="moving-cat" /> {/* 고양이 GIF */}
    </div>
    );
};

export default RightNavbar;